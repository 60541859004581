import React, { useEffect, useState } from 'react';
import {
  Alert,
  App,
  Button,
  Descriptions,
  Flex,
  Image,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tabs,
  Tag,
  theme,
  Typography,
} from 'antd';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ExceptionOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { SHORT_DATE_FORMAT, SHORT_DATETIME_FORMAT } from '../../utils/dateFormatUtils';
import {
  testTubeColorProperties,
  translateIdCardType,
  translateInsuranceCategory,
  translateOrderFileType,
  translateSpecimenFeature,
} from '../../utils/enumHelpers';
import { patientAddress } from '../../utils/patient';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { TestTubeColor } from '../../components/TestTubeColor';
import { filesize } from 'filesize';
import ReactCodeMirror from '@uiw/react-codemirror';
import { useTextResource } from '../../hooks/useTextResource';
import { useObjectUrlResource } from '../../hooks/useObjectUrlResource';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { BillingTypeWithBadge } from '../../components/BillingTypeWithBadge';
import { PdfPreviewModal } from '../../components/PdfPreviewModal';
import { highlightTags, hl7, theme as codemirrorTheme } from '../../styles/codemirror';
import dayjs from 'dayjs';
import { tableActionCell } from '../../styles/globalCss';
import { css } from '@emotion/css';
import { RequestStatusRow } from '../../components/RequestStatusRow';
import { currency } from '../../utils/currency';
import { usePrintEmptyLabel } from '../../hooks/usePrintEmptyLabel';
import { AdditionalDataTable } from '../../components/AdditionalDataTable.tsx';
import { graphql } from '../../graphql/generated';
import { FormFieldType, Gender, OrderFileType, OrderType, RequestQuery } from '../../graphql/generated/graphql.ts';
import { useAuth } from 'react-oidc-context';
import { AlignType } from 'rc-table/lib/interface';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { TableList } from '../../components/TableList.tsx';
import { CostsSummary } from './CostsSummary.tsx';
import { hasSomeRole, Role } from '../../utils/user.ts';

const { useToken } = theme;

export const REQUEST_QUERY = graphql(`
  query Request($id: ID!, $doctorId: ID!) {
    request(id: $id) {
      id
      createdAt
      modifiedAt
      sentAt
      sampledAt
      status
      insuranceShortName
      insuranceName
      importType
      importRequestId
      additionalData
      orders {
        id
        number
        diagnose
        labInfo
        acute
        type
        createdAt
        createdByFirstName
        createdByLastName
        receivedAt
        invoiceNumber
        invoiceCancellationNumber
        orderLabels {
          id
          text
          text2
          number
          name
          classification
          color
          features
          printedAt
          location
          paramShortNames
          specimenId
          testTubeId
        }
        orderParameters {
          id
          shortName
          longName
          diagnose
          billingType
          specialRateCode
          specialRateShortName
          price
          acute
        }
        orderFiles {
          id
          printedAt
          name
          type
          fileName
          size
          printRequired
          printable
          pickup
        }
        orderForms {
          id
          header
          fields {
            id
            values
            label
            type
          }
        }
        lab {
          id
          name
        }
      }
      patientData {
        externalId
        title
        firstName
        lastName
        birthday
        gender
        svnr
        insuranceCategory
        street
        zip
        city
        country
        phone
        email
        idCardType
        idCardNumber
        idCardAuthority
        insuredPerson {
          title
          firstName
          lastName
          birthday
          gender
          svnr
          street
          zip
          city
          country
          phone
          email
        }
      }
      createdByLastName
      createdByFirstName
      modifiedByLastName
      modifiedByFirstName
      sentByFirstName
      sentByLastName
    }

    doctor(id: $doctorId) {
      id
      flipParamNames
      showPrices
    }
  }
`);

const MARK_ORDER_LABEL_PRINTED_MUTATION = graphql(`
  mutation MarkOrderLabelPrinted($orderLabelId: ID!) {
    markOrderLabelPrinted(orderLabelId: $orderLabelId) {
      id
      printedAt
    }
  }
`);

const MARK_ORDER_FILE_PRINTED_MUTATION = graphql(`
  mutation MarkOrderFilePrinted($orderFileId: ID!) {
    markOrderFilePrinted(orderFileId: $orderFileId) {
      id
      printedAt
    }
  }
`);

const CANCEL_ORDER_INVOICE_MUTATION = graphql(`
  mutation CancelOrderInvoice($orderId: ID!) {
    cancelOrderInvoice(orderId: $orderId) {
      id
    }
  }
`);

export type OrderParameter = NonNullable<RequestQuery['request']>['orders'][number]['orderParameters'][number];
export type OrderFile = NonNullable<RequestQuery['request']>['orders'][number]['orderFiles'][number];
export type OrderLabel = NonNullable<RequestQuery['request']>['orders'][number]['orderLabels'][number];
export type OrderFormField = NonNullable<
  RequestQuery['request']
>['orders'][number]['orderForms'][number]['fields'][number];

export const RequestDetailsModal: React.FC<{ requestId: string | null; onClose: () => void }> = ({
  requestId,
  onClose,
}) => {
  const { token } = useToken();
  const auth = useAuth();
  const { currentDoctorId } = useCurrentContextStore();
  const printEmptyLabel = usePrintEmptyLabel();
  const { message } = App.useApp();

  const [activeKey1, setActiveKey1] = useState('auftrag1');
  const [activeKey2, setActiveKey2] = useState('parameter');

  const [previewOrderFile, setPreviewOrderFile] = useState<OrderFile | null>(null);
  const [hl7Content, fetchHl7Content, resetHl7Content, hl7ContentLoading] = useTextResource(() =>
    message.error('Es ist ein Fehler beim Laden des Dokuments aufgetreten')
  );
  const {
    objectUrl: pdfPreviewUrl,
    fetchAndSet: fetchPdfPreviewUrl,
    cleanup: cleanupPdfPreviewUrl,
    loading: pdfPreviewLoading,
  } = useObjectUrlResource(
    () => message.error('Es ist ein Fehler beim Laden des Dokuments aufgetreten'),
    'application/pdf'
  );
  const {
    objectUrl: labelPreviewUrl,
    fetchAndSet: fetchLabelPreviewUrl,
    cleanup: cleanupLabelPreviewUrl,
    loading: labelPreviewLoading,
  } = useObjectUrlResource(
    () => message.error('Es ist ein Fehler beim Laden der Etiketten-Vorschau aufgetreten'),
    'image/png'
  );

  const showOrderFile = (orderFile: OrderFile) => {
    const endpoint = window._env_.API_URL + `/rest/order-file/${orderFile.id}`;
    if (orderFile.type === OrderFileType.HL7) {
      fetchHl7Content(endpoint);
    }
    if (
      orderFile.type === OrderFileType.TRANSFER_DOCUMENT ||
      orderFile.type === OrderFileType.FORM_DOCUMENT ||
      orderFile.type === OrderFileType.INVOICE
    ) {
      fetchPdfPreviewUrl(endpoint);
    }
    setPreviewOrderFile(orderFile);
  };

  const closeOrderFile = () => {
    resetHl7Content();
    cleanupPdfPreviewUrl();
    setPreviewOrderFile(null);
  };

  const { data, loading, refetch } = useQuery(REQUEST_QUERY, {
    variables: {
      id: requestId!,
      doctorId: currentDoctorId,
    },
    skip: !requestId || !currentDoctorId,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    // fix out of bounds tab key
    const tabs =
      [...(data?.request?.orders ?? [])]
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((_, index) => `auftrag${index + 1}`) ?? [];
    if (activeKey1.startsWith('auftrag') && !tabs.includes(activeKey1)) {
      setActiveKey1('auftrag1');
    }
  }, [data, activeKey1]);

  const [markOrderLabelPrintedMutation] = useMutation(MARK_ORDER_LABEL_PRINTED_MUTATION);
  const [markOrderFilePrintedMutation] = useMutation(MARK_ORDER_FILE_PRINTED_MUTATION);
  const [cancelOrderInvoiceMutation] = useMutation(CANCEL_ORDER_INVOICE_MUTATION);

  const printOrderLabels = async (orderLabels: OrderLabel[]) => {
    const sortedLabels = [...orderLabels].sort(
      (a, b) =>
        a.specimenId.localeCompare(b.specimenId) ||
        a.testTubeId.localeCompare(b.testTubeId) ||
        a.location.localeCompare(b.location)
    );
    let error = false;

    try {
      let concatenatedLabels = new ArrayBuffer(0);

      for (const label of sortedLabels) {
        const endpoint = window._env_.API_URL + `/rest/order-label/${label.id}/download`;
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          error = true;
          break;
        }

        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();
        // merging the labels to one print request speeds up printing significantly
        concatenatedLabels = await new Blob([concatenatedLabels, buffer]).arrayBuffer();
      }

      if (concatenatedLabels.byteLength > 0) {
        await window.nativeApi?.print(concatenatedLabels, 'label');
      }
    } catch (e) {
      error = true;
    }

    if (error) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
      return;
    }

    message.success('Druckauftrag erstellt');

    try {
      for (const label of orderLabels) {
        await markOrderLabelPrintedMutation({
          variables: {
            orderLabelId: label.id,
          },
        });
      }

      refetch();
    } catch (e) {
      message.error('Der Druckstatus kann nicht gesetzt werden');
    }
  };

  const printOrderFiles = async (orderFiles: OrderFile[]) => {
    let error = false;

    for (const file of orderFiles) {
      const endpoint = window._env_.API_URL + `/rest/order-file/${file.id}`;
      try {
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          error = true;
          continue;
        }

        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();
        await window.nativeApi?.print(buffer, 'a4');
      } catch (e) {
        error = true;
      }
    }

    if (error) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
      return;
    }

    message.success('Druckauftrag erstellt');

    try {
      for (const file of orderFiles) {
        await markOrderFilePrintedMutation({
          variables: {
            orderFileId: file.id,
          },
        });
      }

      refetch();
    } catch (e) {
      message.error('Der Druckstatus kann nicht gesetzt werden');
    }
  };

  const cancelOrderInvoice = async (orderId: string) => {
    try {
      await cancelOrderInvoiceMutation({
        variables: {
          orderId: orderId,
        },
      });

      refetch();
      message.success('Die Rechnung wurde erfolgreich storniert');
    } catch (e) {
      message.error('Die Rechnung konnte nicht storniert werden');
    }
  };

  const flipParamNames = data?.doctor?.flipParamNames ?? false;
  const showPrices = data?.doctor?.showPrices ?? true;

  const shortNameCol: ColumnType<OrderParameter> = {
    title: 'Kurzname',
    dataIndex: 'shortName',
    key: 'shortName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? undefined : 'ascend',
    width: 160,
    sorter: (a, b) => a.shortName.localeCompare(b.shortName),
    render: v => <Typography.Text style={{ fontWeight: flipParamNames ? 'normal' : 500 }}>{v}</Typography.Text>,
  };

  const longNameCol: ColumnType<OrderParameter> = {
    title: 'Langbezeichnung',
    dataIndex: 'longName',
    key: 'longName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? 'ascend' : undefined,
    width: 110,
    sorter: (a, b) => a.longName.localeCompare(b.longName),
    render: v => <Typography.Text style={{ fontWeight: flipParamNames ? 500 : 'normal' }}>{v}</Typography.Text>,
  };

  const labelColumns: ColumnsType<OrderLabel> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 90,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: v => <Typography.Text style={{ fontWeight: 500 }}>{v}</Typography.Text>,
    },
    {
      title: 'ID',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
      width: 70,
      sorter: (a, b) => a.number.localeCompare(b.number),
    },
    {
      title: 'Kennung',
      dataIndex: 'classification',
      key: 'classification',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.classification.localeCompare(b.classification),
    },
    {
      title: 'Farbe',
      dataIndex: 'color',
      key: 'color',
      width: 90,
      sorter: (a, b) => a.color.localeCompare(b.color),
      render: value => <TestTubeColor properties={testTubeColorProperties(value)} withText />,
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true,
      width: 90,
      sorter: (a, b) => a.text.localeCompare(b.text),
    },
    {
      title: 'Text 2',
      dataIndex: 'text2',
      key: 'text2',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.text2.localeCompare(b.text2),
    },
    {
      title: 'Lokalisation',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      width: 120,
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: 'Letzter Druck am',
      dataIndex: 'printedAt',
      key: 'printedAt',
      width: 160,
      ellipsis: true,
      sorter: (a, b) => (a.printedAt ?? '').localeCompare(b.printedAt ?? ''),
      render: value => (value ? dayjs(value).format(SHORT_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Eigenschaften',
      dataIndex: 'features',
      key: 'features',
      ellipsis: true,
      width: 120,
      render: (_, record) => (
        <Popover
          trigger={['click']}
          content={
            <ul
              style={{
                margin: 0,
                padding: 0,
                listStyle: 'none',
              }}
            >
              {record.features.map(feature => (
                <li key={feature}>{translateSpecimenFeature(feature)}</li>
              ))}
            </ul>
          }
        >
          <Button type="link" style={{ height: 'auto' }} icon={<EyeOutlined />} hidden={!record.features.length} />
        </Popover>
      ),
    },
    {
      title: 'Parameter',
      dataIndex: 'params',
      key: 'params',
      width: 100,
      ellipsis: true,
      render: (_, record) => <TableList entries={record.paramShortNames} maxEntries={3} />,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '90px',
      className: tableActionCell,
      render: (_, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            type="link"
            onClick={() => fetchLabelPreviewUrl(window._env_.API_URL + `/rest/order-label/${record.id}/preview`)}
          />{' '}
          <Button
            icon={<PrinterOutlined />}
            disabled={!window.nativeApi}
            type="link"
            onClick={() => printOrderLabels([record])}
          />
        </>
      ),
    },
  ];

  const fileColumns: ColumnsType<OrderFile> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 100,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: v => <Typography.Text style={{ fontWeight: 500 }}>{v}</Typography.Text>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: value => translateOrderFileType(value),
    },
    {
      title: 'Dateigröße',
      dataIndex: 'size',
      key: 'size',
      width: 110,
      sorter: (a, b) => a.size - b.size,
      render: (_, record) => filesize(record.size, { locale: 'de' }) as string,
    },
    {
      title: 'Letzter Druck am',
      dataIndex: 'printedAt',
      key: 'printedAt',
      width: 150,
      sorter: (a, b) => (a.printedAt ?? '').localeCompare(b.printedAt ?? ''),
      render: value => (value ? dayjs(value).format(SHORT_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Elekt. übertragen',
      dataIndex: 'pickup',
      key: 'pickup',
      width: 150,
      ellipsis: true,
      sorter: (a, b) => Number(a.pickup) - Number(b.pickup),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '60px',
      className: tableActionCell,
      render: (_, record) => (
        <Flex>
          <Button icon={<EyeOutlined />} type="link" onClick={() => showOrderFile(record)} />
          {record.printable && (
            <Button
              icon={<PrinterOutlined />}
              type="link"
              disabled={!window.nativeApi}
              onClick={() => printOrderFiles([record])}
            />
          )}
        </Flex>
      ),
    },
  ];

  const formFieldColumns: ColumnsType<OrderFormField> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.label.localeCompare(b.label),
    },
    {
      title: 'Wert(e)',
      dataIndex: 'values',
      key: 'values',
      ellipsis: true,
      render: (_, record) => {
        if (record.type === FormFieldType.DATE) {
          return record.values.length ? <Tag>{dayjs(record.values[0]).format(SHORT_DATE_FORMAT)}</Tag> : '';
        }
        return record.values.map((v, i) => <Tag key={i}>{v}</Tag>);
      },
    },
  ];

  const insuredPerson = data?.request?.patientData.insuredPerson;
  const cmTheme = codemirrorTheme(token.colorBorderSecondary, token.colorPrimaryActive);

  return (
    <Modal
      title={
        <>
          <InfoCircleOutlined /> Anforderung {data?.request?.patientData.title} {data?.request?.patientData.firstName}{' '}
          {data?.request?.patientData.lastName}{' '}
          {data?.request?.importType ? (
            <Tag color="warning" className={css(`vertical-align: bottom`)}>
              Importiert
            </Tag>
          ) : null}
        </>
      }
      okText="Schließen"
      okButtonProps={{ icon: <CloseOutlined /> }}
      open={!!requestId}
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ hidden: true }}
      width="80%"
      style={{ minWidth: '768px', maxWidth: '1200px' }}
    >
      {loading ? (
        <LoadingIndicator height="200px" />
      ) : (
        <Tabs
          activeKey={activeKey1}
          size="small"
          onChange={setActiveKey1}
          items={[
            ...[
              {
                key: 'anforderung',
                label: 'Anforderung',
                children: (
                  <>
                    <Descriptions column={1} size="small" bordered labelStyle={{ width: '200px' }}>
                      <Descriptions.Item label="Status">
                        {data?.request && <RequestStatusRow request={data.request} />}
                      </Descriptions.Item>
                      <Descriptions.Item label="Erstellt am">
                        {dayjs(data?.request?.createdAt).format(SHORT_DATETIME_FORMAT)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Erstellt von">
                        {data?.request?.createdByFirstName} {data?.request?.createdByLastName}
                      </Descriptions.Item>
                      {!!data?.request?.modifiedAt && (
                        <Descriptions.Item label="Zuletzt bearbeitet am">
                          {dayjs(data.request.modifiedAt).format(SHORT_DATETIME_FORMAT)}
                        </Descriptions.Item>
                      )}
                      {!!(data?.request?.modifiedByFirstName || data?.request?.modifiedByLastName) && (
                        <Descriptions.Item label="Zuletzt bearbeitet von">
                          {data?.request?.modifiedByFirstName} {data?.request?.modifiedByLastName}
                        </Descriptions.Item>
                      )}
                      {!!data?.request?.sentAt && (
                        <Descriptions.Item label="Gesendet am">
                          {dayjs(data?.request?.sentAt).format(SHORT_DATETIME_FORMAT)}
                        </Descriptions.Item>
                      )}
                      {!!(data?.request?.sentByFirstName || data?.request?.sentByLastName) && (
                        <Descriptions.Item label="Gesendet von">
                          {data?.request?.sentByFirstName} {data?.request?.sentByLastName}
                        </Descriptions.Item>
                      )}
                      {!!data?.request?.sampledAt && (
                        <Descriptions.Item label="Probenentnahme am">
                          {dayjs(data?.request?.sampledAt).format(SHORT_DATETIME_FORMAT)}
                        </Descriptions.Item>
                      )}
                      {!!data?.request?.importRequestId && (
                        <Descriptions.Item label="Import-ID">{data?.request?.importRequestId}</Descriptions.Item>
                      )}
                    </Descriptions>
                    {!!data?.request?.additionalData && Object.keys(data.request.additionalData).length > 0 && (
                      <>
                        <p />
                        <h3>Zusatzdaten</h3>
                        <AdditionalDataTable additionalData={data.request.additionalData} inlineScroll />
                      </>
                    )}
                  </>
                ),
              },
              {
                key: 'patient',
                label: 'Patient',
                children: (
                  <Tabs
                    defaultActiveKey="p"
                    type="card"
                    items={[
                      {
                        key: 'p',
                        label: 'Patient',
                        children: (
                          <>
                            <Button
                              icon={<PrinterOutlined />}
                              disabled={!window.nativeApi}
                              onClick={() =>
                                printEmptyLabel(
                                  {
                                    doctorId: currentDoctorId,
                                    patientTitle: data?.request?.patientData?.title ?? '',
                                    patientLastName: data?.request?.patientData?.lastName ?? '',
                                    patientFirstName: data?.request?.patientData?.firstName ?? '',
                                    patientSvnr: data?.request?.patientData?.svnr ?? '',
                                  },
                                  1,
                                  () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
                                  () => message.success('Leer-Etikett wurde an den Drucker gesendet')
                                )
                              }
                            >
                              Leer-Etikett drucken
                            </Button>
                            <p />
                            <Descriptions column={1} size="small" bordered labelStyle={{ width: '200px' }}>
                              <Descriptions.Item label="AIS-ID">
                                {data?.request?.patientData.externalId}
                              </Descriptions.Item>
                              <Descriptions.Item label="Titel & Name">
                                {data?.request?.patientData.title} {data?.request?.patientData.firstName}{' '}
                                {data?.request?.patientData.lastName}
                              </Descriptions.Item>
                              <Descriptions.Item label="Geburtstag">
                                {dayjs(data?.request?.patientData.birthday).format(SHORT_DATE_FORMAT)}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label="Geschlecht">
                                {data?.request?.patientData.gender === Gender.MALE ? 'männlich' : 'weiblich'}
                              </Descriptions.Item>
                              <Descriptions.Item label="SVNR">{data?.request?.patientData.svnr}</Descriptions.Item>
                              <Descriptions.Item label="Kasse">
                                {data?.request?.insuranceShortName} - {data?.request?.insuranceName}
                              </Descriptions.Item>
                              <Descriptions.Item label="Versicherungskategorie">
                                {translateInsuranceCategory(data?.request?.patientData.insuranceCategory ?? '')}
                              </Descriptions.Item>
                              <Descriptions.Item label="Adresse">
                                {patientAddress(data?.request?.patientData)}
                              </Descriptions.Item>
                              <Descriptions.Item label="Telefon">
                                <a href={`tel:${data?.request?.patientData.phone}`}>
                                  {data?.request?.patientData.phone}
                                </a>
                              </Descriptions.Item>
                              <Descriptions.Item label="E-Mail">
                                <a href={`mailto:${data?.request?.patientData.email}`}>
                                  {data?.request?.patientData.email}
                                </a>
                              </Descriptions.Item>
                              <Descriptions.Item label="Ausweis">
                                {translateIdCardType(data?.request?.patientData.idCardType ?? '')}
                              </Descriptions.Item>
                              <Descriptions.Item label="Ausweisnummer">
                                {data?.request?.patientData.idCardNumber}
                              </Descriptions.Item>
                              <Descriptions.Item label="Ausstellende Behörde">
                                {data?.request?.patientData.idCardAuthority}
                              </Descriptions.Item>
                            </Descriptions>
                          </>
                        ),
                      },
                      {
                        key: 'v',
                        label: 'Hauptversicherte Person',
                        children: insuredPerson ? (
                          <Descriptions column={1} size="small" bordered labelStyle={{ width: '200px' }}>
                            <Descriptions.Item label="Titel & Name">
                              {insuredPerson.title} {insuredPerson.firstName} {insuredPerson.lastName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Geburtstag">
                              {dayjs(insuredPerson.birthday).format(SHORT_DATE_FORMAT)}{' '}
                            </Descriptions.Item>
                            {!!insuredPerson.gender && (
                              <Descriptions.Item label="Geschlecht">
                                {insuredPerson.gender === Gender.MALE ? 'männlich' : 'weiblich'}
                              </Descriptions.Item>
                            )}
                            <Descriptions.Item label="SVNR">{insuredPerson.svnr}</Descriptions.Item>
                            <Descriptions.Item label="Kasse">
                              {data?.request?.insuranceShortName} - {data?.request?.insuranceName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Versicherungskategorie">
                              {translateInsuranceCategory(data?.request?.patientData.insuranceCategory ?? '')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Adresse">{patientAddress(insuredPerson)}</Descriptions.Item>
                            <Descriptions.Item label="Telefon">
                              <a href={`tel:${insuredPerson.phone}`}>{insuredPerson.phone}</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="E-Mail">
                              <a href={`mailto:${insuredPerson.email}`}>{insuredPerson.email}</a>
                            </Descriptions.Item>
                          </Descriptions>
                        ) : (
                          <Typography.Text type="secondary">Die Patient ist nicht mitversichert</Typography.Text>
                        ),
                      },
                    ]}
                  />
                ),
              },
            ],
            ...[...(data?.request?.orders ?? [])]
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
              .map((order, index) => {
                const diagnoses = order.orderParameters
                  .reduce<string[]>(
                    (diagnoses, param) =>
                      diagnoses.some(d => d === param.diagnose) ? diagnoses : [...diagnoses, param.diagnose],
                    []
                  )
                  .filter(it => it !== '')
                  .join('\n');

                const printableOrderFiles = order.orderFiles.filter(it => it.printable);
                const hasAcuteParams = order.orderParameters.some(it => it.acute) ?? false;

                const paramColumns: ColumnsType<OrderParameter> = [
                  ...(flipParamNames ? [longNameCol, shortNameCol] : [shortNameCol, longNameCol]),
                  {
                    title: 'Verrechnung',
                    dataIndex: 'billingType',
                    key: 'billingType',
                    width: 120,
                    sorter: (a, b) => a.billingType.localeCompare(b.billingType),
                    render: value => <BillingTypeWithBadge billingType={value} />,
                  },
                  ...(showPrices
                    ? [
                        {
                          title: 'Preis',
                          dataIndex: 'price',
                          key: 'price',
                          width: 60,
                          ellipsis: true,
                          sorter: (a, b) => (a.price ?? -1) - (b.price ?? -1),
                          render: value => currency(value),
                        } as ColumnType<OrderParameter>,
                      ]
                    : []),
                  ...(hasAcuteParams && order.acute
                    ? [
                        {
                          title: 'Akut',
                          dataIndex: 'acute',
                          key: 'acute',
                          width: 70,
                          align: 'center' as AlignType,
                          sorter: (a, b) => Number(a.acute) - Number(b.acute),
                          render: (_, record) => {
                            return record.acute ? (
                              <ClockCircleOutlined
                                className={css`
                                  font-size: 16px;
                                `}
                              />
                            ) : (
                              ''
                            );
                          },
                        } as ColumnType<OrderParameter>,
                      ]
                    : []),
                  {
                    title: 'Sondertarif',
                    dataIndex: 'specialRateShortName',
                    key: 'specialRateShortName',
                    ellipsis: true,
                    width: 110,
                    sorter: (a, b) => a.specialRateShortName.localeCompare(b.specialRateShortName),
                  },
                ];

                const tabs = [
                  {
                    key: 'auftrag',
                    label: 'Auftrag',
                    children: (
                      <Descriptions column={1} size="small" bordered labelStyle={{ width: '200px' }}>
                        <Descriptions.Item label="Auftragsnummer">{order.number}</Descriptions.Item>
                        <Descriptions.Item label="Erstellt am">
                          {dayjs(order.createdAt).format(SHORT_DATETIME_FORMAT)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Erstellt von">
                          {order.createdByFirstName} {order.createdByLastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Empfangen am">
                          {order.receivedAt ? (
                            dayjs(order.receivedAt).format(SHORT_DATETIME_FORMAT)
                          ) : (
                            <Typography.Text type="secondary">
                              Das Labor hat den Auftrag noch nicht erhalten
                            </Typography.Text>
                          )}
                        </Descriptions.Item>
                        {order.acute && (
                          <Descriptions.Item label="Akut angefordert">
                            <ClockCircleOutlined /> Ja
                          </Descriptions.Item>
                        )}
                        {!!order.diagnose && (
                          <Descriptions.Item label="Diagnosen und Informationen für die Befundvalidierung">
                            <pre style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>{order.diagnose}</pre>
                          </Descriptions.Item>
                        )}
                        {!!order.labInfo && (
                          <Descriptions.Item label="Informationen ans Labor">{order.labInfo}</Descriptions.Item>
                        )}
                        {!!diagnoses && (
                          <Descriptions.Item label="Diagnosen für die Verrechnung mit der Kasse">
                            <pre style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>{diagnoses}</pre>
                          </Descriptions.Item>
                        )}
                        {data?.doctor?.showPrices && !data?.request?.importType && (
                          <Descriptions.Item label="Kosten">
                            <CostsSummary
                              entries={order.orderParameters.map(it => ({
                                billingType: it.billingType,
                                price: it.price ?? null,
                                text: it.specialRateShortName,
                                pricePatient: null,
                              }))}
                              showPriceSuggestion={false}
                              compact
                            />
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    ),
                  },
                  {
                    key: 'parameter',
                    label: 'Parameter',
                    children: (
                      <Table<OrderParameter>
                        scroll={{ y: '300px', x: 'max-content' }}
                        rowKey={param => param.id}
                        size="small"
                        showSorterTooltip={false}
                        dataSource={order.orderParameters}
                        pagination={false}
                        columns={paramColumns}
                      />
                    ),
                  },
                  {
                    key: 'etiketten',
                    label: 'Etiketten',
                    children: order.orderLabels.length ? (
                      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Button
                          icon={<PrinterOutlined />}
                          disabled={!window.nativeApi}
                          onClick={() => printOrderLabels(order.orderLabels)}
                        >
                          Alle Etiketten drucken
                        </Button>
                        <Table<OrderLabel>
                          scroll={{ y: '300px', x: 'max-content' }}
                          rowKey={label => label.id}
                          size="small"
                          showSorterTooltip={false}
                          dataSource={order.orderLabels}
                          pagination={false}
                          columns={labelColumns}
                        />
                      </Space>
                    ) : (
                      <Typography.Text type="secondary">Es werden keine Etiketten benötigt</Typography.Text>
                    ),
                  },
                  {
                    key: 'files',
                    label: 'Dokumente',
                    children: order.orderFiles.length ? (
                      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Flex gap="small">
                          <Button
                            icon={<PrinterOutlined />}
                            disabled={!printableOrderFiles.length || !window.nativeApi}
                            onClick={() => printOrderFiles(printableOrderFiles)}
                          >
                            Alle Dokumente drucken
                          </Button>
                          {hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_FRONT_DESK], auth.user) &&
                            order.invoiceNumber &&
                            !order.invoiceCancellationNumber && (
                              <Popconfirm
                                title="Rechnung stornieren?"
                                onConfirm={() => cancelOrderInvoice(order.id)}
                                okText="Ja, stornieren"
                                okButtonProps={{ danger: true }}
                                cancelText="Abbrechen"
                                placement="bottom"
                              >
                                <Button icon={<ExceptionOutlined />} danger>
                                  Storno-Rechnung erzeugen
                                </Button>
                              </Popconfirm>
                            )}
                        </Flex>
                        <Table<OrderFile>
                          scroll={{ y: '300px', x: 'max-content' }}
                          rowKey={file => file.id}
                          size="small"
                          showSorterTooltip={false}
                          dataSource={order.orderFiles.filter(
                            it => it.type !== OrderFileType.ETRON && it.type !== OrderFileType.READY
                          )}
                          pagination={false}
                          columns={fileColumns}
                        />
                      </Space>
                    ) : (
                      <Typography.Text type="secondary">Keine Dokumente vorhanden</Typography.Text>
                    ),
                  },
                  {
                    key: 'forms',
                    label: 'Formulare',
                    children: order.orderForms.filter(it => it.fields.length).length ? (
                      <div
                        className={css`
                          max-height: 400px;
                          overflow: auto;
                        `}
                      >
                        {order.orderForms
                          .filter(it => it.fields.length)
                          .map(form => {
                            return (
                              <Table<OrderFormField>
                                key={form.id}
                                title={() => (
                                  <h3
                                    className={css`
                                      margin-bottom: 0;
                                    `}
                                  >
                                    {form.header}
                                  </h3>
                                )}
                                rowKey={field => field.id}
                                size="small"
                                showSorterTooltip={false}
                                dataSource={form.fields}
                                pagination={false}
                                columns={formFieldColumns}
                                className={css`
                                  .ant-table-title {
                                    padding-left: 0 !important;
                                  }
                                `}
                              />
                            );
                          })}
                      </div>
                    ) : (
                      <Typography.Text type="secondary">Keine Formulare ausgefüllt</Typography.Text>
                    ),
                  },
                ];

                return {
                  key: `auftrag${index + 1}`,
                  label:
                    order.type === OrderType.INITIAL ? (
                      <>Auftrag {order.lab.name}</>
                    ) : (
                      <>
                        <PlusCircleOutlined style={{ marginRight: '2px' }} /> Nachf. {order.lab.name}
                      </>
                    ),
                  children: <Tabs type="card" activeKey={activeKey2} onChange={setActiveKey2} items={tabs} />,
                };
              }),
          ]}
        />
      )}
      <Modal
        title={previewOrderFile?.fileName}
        open={hl7Content.length > 0 || hl7ContentLoading}
        width="80%"
        style={{ maxWidth: '1200px' }}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ icon: <CloseOutlined /> }}
        okText="Schließen"
        onOk={closeOrderFile}
        onCancel={closeOrderFile}
        destroyOnClose
      >
        {hl7ContentLoading ? (
          <LoadingIndicator />
        ) : (
          <ReactCodeMirror
            value={hl7Content}
            width="100%"
            height="400px"
            basicSetup={{ lineNumbers: true }}
            extensions={[hl7, highlightTags]}
            theme={cmTheme}
            editable={false}
            // wait for the modal transition to finish
            onCreateEditor={(view, state) => setTimeout(() => view.setState(state), 300)}
          />
        )}
      </Modal>
      <Modal
        open={!!labelPreviewUrl || labelPreviewLoading}
        onCancel={() => cleanupLabelPreviewUrl()}
        onOk={() => cleanupLabelPreviewUrl()}
        cancelButtonProps={{ hidden: true }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {labelPreviewLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Image src={labelPreviewUrl} style={{ maxWidth: '100%', border: '1px solid black' }} preview={false} />
              <p />
              <Alert
                type="warning"
                showIcon
                message="Das angezeigte Etikett mit anonymisierten Daten dient rein als Vorschau des Originals und darf keinesfalls weiterverwendet werden."
              />
            </>
          )}
        </div>
      </Modal>
      <PdfPreviewModal
        title={previewOrderFile?.fileName ?? ''}
        url={pdfPreviewUrl}
        loading={pdfPreviewLoading}
        onClose={closeOrderFile}
      />
    </Modal>
  );
};
